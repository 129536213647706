<template>
    <div class="content-page">
        <component-navbar></component-navbar>
        <router-view></router-view>
        <component-bottom></component-bottom>
    </div>
</template>

<script>

    import ComponentNavbar from "@/components/ComponentNavbar.vue";
    import ComponentBottom from "@/components/ComponentBottom.vue";

    export default {
        name: "Content",
        components: {
            ComponentNavbar,
            ComponentBottom
        }
    }
</script>

<style lang="less" scoped>

</style>